
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import type { TextArray } from '@/definitions/shared/types'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'

export default defineComponent({
  components: {
    TmStylizedText,
    TmModal,
    TmButton,
    TmFormLine,
  },
  props: {
    placeholder: {
      type: String,
    },
    description: {
      type: [String, Array] as PropType<string | TextArray[]>,
    },
    btnText: {
      type: String,
      default: 'Save',
    },
    label: {
      type: String,
    },
    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
    modalTitle: {
      type: String,
      default: 'Tags modal',
    },
  },
  setup(props) {
    const val = ref(props.value)

    return {
      val,
    }
  },
})
